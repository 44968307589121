import React, { } from 'react';
import PortalDocuments from '../../../Views/Portal/Documents/Documents';
import PortalAccount from '../../../Views/Portal/Account/Account';
import PortalNav from '../../../components/PortalNav/PortalNav';
import PortalAccountSecurity from '../../../Views/Portal/Account/Security/Security';
import PortalAccountPayments from '../../../Views/Portal/Account/Payments/Payments';
import PortalPackage from '../../../Views/Portal/Package/Package';
import { Router } from "@reach/router";
import PortalSmartHub from '../../../Views/Portal/SmartHub/SmartHub';
import ConsultantPortalCustomers from '../../../Views/ConsultantPortal/Customers/Customers';
import PortalCompanyCalendar from '../../../Views/Portal/CompanyCalendar/CompanyCalendar';
import PortalDepartments from '../../../Views/Portal/Employees/Departments/Departments';
import PortalEmployeeDocuments from '../../../Views/Portal/Employees/Documents/Documents';
import PortalEmployee from '../../../Views/Portal/Employees/Employee/Employee';
import PortalEmployees from '../../../Views/Portal/Employees/Employees';
import PortalHierarchy from '../../../Views/Portal/Employees/Hierarchy/Hierarchy';
import PortalPolicyLibrary from '../../../Views/Portal/Employees/Policy-Library/Policy-Library';
import PortalPolicy from '../../../Views/Portal/Employees/Policy-Library/Policy/Policy';
import PortalTeams from '../../../Views/Portal/Employees/Teams/Teams';
import RecruitmentWorkflow from '../../../Views/Portal/Tools/Workflows/RecruitmentWorkflow/Recruitment/Recruitment';
import RecruitmentWorkflowList from '../../../Views/Portal/Tools/Workflows/RecruitmentWorkflow/RecruitmentList';
import PortalTeam from '../../../Views/Portal/Employees/Teams/Team/Team';
import PortalDepartment from '../../../Views/Portal/Employees/Departments/Department/Department';
import PortalReports from '../../../Views/Portal/AdviceLine/Reports/Reports';
import PortalAdvice from '../../../Views/Portal/AdviceLine/AdviceLine';
import OnboardingWorkflowList from '../../../Views/Portal/Tools/Workflows/OnboardingWorkflow/OnboardingList';
import OnboardingWorkflow from '../../../Views/Portal/Tools/Workflows/OnboardingWorkflow/Onboarding/Onboarding';
import { Package } from '../../../Classes/Package';
import PortalAccountBusiness from '../../../Views/Portal/Account/Business/Business';
import BenefitsPerksList from '../../../Views/Portal/Benefits/Benefits';
import AssetManagement from '../../../Views/Portal/Assets/Assets';
import AssetForm from '../../../Views/Portal/Assets/Asset/Asset';
import OffboardingWorkflowList from '../../../Views/Portal/Tools/Workflows/OffboardingWorkflow/OffboardingList';
import OffboardingWorkflow from '../../../Views/Portal/Tools/Workflows/OffboardingWorkflow/Offboarding/Offboarding';
import PortalLayout from '../../../components/Layout/PortalLayout';
import PortalMoodMonitor from '../../../Views/Portal/MoodMonitor/MoodMonitor';
import PortalTaskManager from '../../../Views/Portal/Employees/TaskManager/TaskManager';
import Courses from '../../../Views/Portal/Courses/Courses/Table/Courses';
import Modules from '../../../Views/Portal/Courses/Modules/Table/Modules';
import Lessons from '../../../Views/Portal/Courses/Lessons/Table/Lessons';
import Lesson from '../../../Views/Portal/Courses/Lessons/Lesson/Lesson';

const ConsultantPortalPage: React.FC = (props: any) => {
  const Packages: Package[] = props.pageContext.Packages;

  return (<PortalLayout>
    <PortalNav PackageData={Packages}>
      <Router basepath="/Consultant/Portal" className={"router"}>
        <PrivateRoute path="/Documents" component={PortalDocuments} />

        <PrivateRoute path="/Account/Security" component={PortalAccountSecurity} />
        <PrivateRoute path="/Account/Payments" component={PortalAccountPayments} />
        <PrivateRoute path="/Account/Business" component={PortalAccountBusiness} />
        <PrivateRoute path="/Account" component={PortalAccount} />

        <PrivateRoute path="/Package" component={PortalPackage} />

        <PrivateRoute path="/AdviceLine/Reports" component={PortalReports} />
        <PrivateRoute path="/AdviceLine" component={PortalAdvice} />
        <PrivateRoute path="/Clients" component={ConsultantPortalCustomers} />

        <ConsultantPortalCustomers path="/Clients/:CustomerID">
          <PrivateRoute path="/Company%20Calendar" component={PortalCompanyCalendar} />
          <PrivateRoute path="/Mood%20Monitor" component={PortalMoodMonitor} />

          <PrivateRoute path="/Storage" component={PortalSmartHub} />
          <PrivateRoute path="/Storage/:ID" component={PortalSmartHub} />

          <PrivateRoute path="/Employees/Employee/:ID/:Tab" component={PortalEmployee} />
          <PrivateRoute path="/Employees/Employee/:ID" component={PortalEmployee} />
          <PrivateRoute path="/Employees/Documents/:ID" component={PortalEmployeeDocuments} />
          <PrivateRoute path="/Task%20Manager" component={PortalTaskManager} />
          <PrivateRoute path="/Heirarchy" component={PortalHierarchy} />
          <PrivateRoute path="/Policy%20Library" component={PortalPolicyLibrary} />
          <PrivateRoute path="/Policy%20Library/Policy/:ID" component={PortalPolicy} />
          <PrivateRoute path="/Employees" component={PortalEmployees} />
          <PrivateRoute path="/Benefits" component={BenefitsPerksList} />
          <PrivateRoute path="/Asset%20Management" component={AssetManagement} />
          <PrivateRoute path="/Asset%20Management/Asset/:ID" component={AssetForm} />

          <PrivateRoute path="/Departments" component={PortalDepartments} />
          <PrivateRoute path="/Departments/Department/:ID" component={PortalDepartment} />
          <PrivateRoute path="/Teams" component={PortalTeams} />
          <PrivateRoute path="/Teams/:ID" component={PortalTeams} />
          <PrivateRoute path="/Teams/Team/:ID" component={PortalTeam} />

          <PrivateRoute path="/Recruitment" component={RecruitmentWorkflowList} />
          <PrivateRoute path="/Recruitment/Item/:ID" component={RecruitmentWorkflow} />

          <PrivateRoute path="/Onboarding" component={OnboardingWorkflowList} />
          <PrivateRoute path="/Onboarding/Item/:ID" component={OnboardingWorkflow} />

          <PrivateRoute path="/Offboarding" component={OffboardingWorkflowList} />
          <PrivateRoute path="/Offboarding/Item/:ID" component={OffboardingWorkflow} />


          <PrivateRoute path={"/Courses"} component={Courses} />
          <PrivateRoute path={"/Courses/Modules/:CourseID"} component={Modules} />
          <PrivateRoute path={"/Courses/Modules/Lessons/:ModuleID"} component={Lessons} />
          <PrivateRoute path={"/Courses/Modules/Lesson/:LessonID/Slide/:SlideID"} component={Lesson} />
        </ConsultantPortalCustomers>
      </Router>
    </PortalNav>
  </PortalLayout>);
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Component {...rest} />
}

export default ConsultantPortalPage;
