// extracted by mini-css-extract-plugin
export var Active = "Customers-module--Active--11491";
export var Body = "Customers-module--Body--02988";
export var BodyInner = "Customers-module--BodyInner--ba0a7";
export var Header = "Customers-module--Header--50926";
export var HeaderInner = "Customers-module--HeaderInner--e689b";
export var Main = "Customers-module--Main--40efd";
export var Message = "Customers-module--Message--5931d";
export var Orange = "Customers-module--Orange--78291";
export var Progress = "Customers-module--Progress--9ca05";
export var Red = "Customers-module--Red--c21bd";
export var Show = "Customers-module--Show--79d5a";
export var Side = "Customers-module--Side--e8c92";
export var SideInner = "Customers-module--SideInner--f9ebc";
export var SideNav = "Customers-module--SideNav--d6b5c";
export var SideNavBack = "Customers-module--SideNavBack--c82b8";
export var SideNavBody = "Customers-module--SideNavBody--359ee";
export var SideNavHeader = "Customers-module--SideNavHeader--25e7a";
export var SideNavInner = "Customers-module--SideNavInner--a30b7";
export var Storage = "Customers-module--Storage--59788";
export var StorageProgress = "Customers-module--StorageProgress--5829a";