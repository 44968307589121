import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Customers.module.scss';
import { OrganisationContext, UserContext } from '../../../Context/UserContext';
import { Link, navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faCalendar, faChevronLeft, faChevronRight, faDownload, faGraduationCap, faLaptop, faPlus, faSignature, faSitemap, faSpinner, faTasks, faThList, faUserAltSlash, faUserFriends, faUserPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard, faFaceGrinBeam, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Customer } from '../../../Classes/Customer';
import { API_ConsultantCustomers, API_ConsultantSaveCustomer, API_Organisation } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import { Modal } from '../../../components/Modal/Modal';
import Input from '../../../components/Input/input';
import { DecryptValue, EncryptValue } from '../../../Services/Crypto';
import { BankHolidays, Department, Employee, HolidayEvent, Organisation, Team, WorkDay } from '../../../Classes/Employee';
import { Loading } from '../../../components/Loading/Loading';

const ConsultantPortalCustomers: React.FC = (props: any) => {
  const CustomerID: string = props.CustomerID ? DecryptValue(props.CustomerID) : "";
  const { setOrganisation } = useContext(OrganisationContext);
  const { User, Language } = useContext(UserContext);
  const [Customer, setCustomer] = useState<Customer>({} as Customer);
  const [customers, setCustomers] = useState<Customer[]>([] as Customer[]);
  const [addCustomer, setAddCustomer] = useState<boolean>(false);
  const [newCustomer, setNewCustomer] = useState<Customer>({} as Customer);
  const [newCustomerSaving, setNewCustomerSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const entitlement: number = 5.6;
  const maxEntitlement: number = 28;

  useEffect(() => {
    if (User.ID)
      GetData();
  }, [User, props])

  useEffect(() => {
    if (CustomerID)
      GetCustomerEmployees();
    else
      setOrganisation({ Employees: [] as Employee[], Teams: [] as Team[], Departments: [] as Department[] } as Organisation);
  }, [CustomerID])

  const GetCustomerEmployees = async () => {
    setLoading(true);
    await Fetch(`${API_Organisation}`, {
      CustomerID: parseInt(CustomerID),
      CompanyID: User.ID,
      PackageID: User.PackageID
    }).then(async (Response: Organisation) => {
      if (Response.Employees.length) {
        Response.BankHolidays = await GetBankHolidays();
        Response.Employees = Response.Employees.map((emp: Employee) => {
          var days = RemainingHolidays(`${new Date(emp.Business.CurrentEmployment.StartDate).getFullYear()}-01-01`, emp, Response.BankHolidays);
          var banks = !emp.Business.CurrentEmployment.worksBankHolidays ? (GetWorkedBankHolidays(`${new Date(emp.Business.CurrentEmployment.StartDate).getFullYear()}-01-01`, emp, Response.BankHolidays)).length : 0;
          emp.Business.HolidayEntitlement.StatutoryDays = (days + banks).toFixed()

          emp.CalendarEvents = emp.CalendarEvents.map(e => {
            e.SettingsJSON = JSON.parse(e.Settings);
            return e;
          })
          return emp;
        });
      }

      setOrganisation(Response);
    });
    setLoading(false);
  }

  const DaysRemaining = (from: string, Employee: Employee) => {
    var startDate = new Date(from);
    var endOfYear = new Date(`${startDate.getFullYear() + 1}-01-01`);
    var daysOfYear: Date[] = [];
    for (var d = startDate; d <= endOfYear; d.setDate(d.getDate() + 1)) {
      var date = new Date(d);
      if ((Employee.Business.CurrentEmployment.WorkDays ? JSON.parse(Employee.Business.CurrentEmployment.WorkDays) as WorkDay[] : [] as WorkDay[]).find((x: WorkDay) => x.Day === date.getDay()))
        daysOfYear.push(date);
    }

    return daysOfYear.length ? Math.round(daysOfYear.length * 2) / 2 : 0;
  }

  const RemainingHolidays = (from: string, Employee: Employee, BankHolidays: BankHolidays[]) => {
    let perYear = (Employee.Business.CurrentEmployment.WorkDays ? JSON.parse(Employee.Business.CurrentEmployment.WorkDays) as WorkDay[] : [] as WorkDay[]).length * entitlement;
    perYear = perYear > maxEntitlement ? maxEntitlement : perYear;
    let remaining = Employee.Business.CurrentEmployment.WorkDays ? (perYear / 26) * (DaysRemaining(from, Employee) / ((JSON.parse(Employee.Business.CurrentEmployment.WorkDays) as WorkDay[]).length * 2)) : 0;
    remaining = remaining > maxEntitlement ? maxEntitlement : remaining;

    return remaining ? Math.round((remaining - (!Employee.Business.CurrentEmployment.worksBankHolidays ? GetWorkedBankHolidays(from, Employee, BankHolidays).length : 0)) * 10) / 10 : 0;
  }

  const GetWorkedBankHolidays = (from: string, Employee: Employee, BankHolidays: BankHolidays[]) => {
    let holidays: BankHolidays | undefined = BankHolidays.find(d => d.Year === new Date(from).getFullYear());
    if (holidays)
      return holidays.Days.filter(day => (Employee.Business.CurrentEmployment.WorkDays ? JSON.parse(Employee.Business.CurrentEmployment.WorkDays) as WorkDay[] : [] as WorkDay[]).find(x => x.Day === new Date(day.date).getDay()) && (new Date(day.date) >= new Date(from)))

    return [] as HolidayEvent[];
  }

  const GetBankHolidays = async () => {
    let holidays: BankHolidays[] = [] as BankHolidays[];

    await fetch("https://www.gov.uk/bank-holidays.json")
      .then((res) => res.json()).then((res) => {
        res["england-and-wales"]["events"].map((event: HolidayEvent) => {
          let index: number = holidays.findIndex(h => h.Year === new Date(event.date).getFullYear());
          if (index >= 0) {
            holidays[index].Days.push(event);
          } else {
            holidays.push({ Year: new Date(event.date).getFullYear(), Days: [event] as HolidayEvent[] } as BankHolidays);
          }
        });
      });

    return holidays;
  }

  const GetData = async () => {
    setLoading(true);
    await Fetch(`${API_ConsultantCustomers}`).then((Response: Customer[]) => {
      setCustomers(Response);
      if (CustomerID) {
        let c: Customer | undefined = Response.find(cu => cu.ID === parseInt(CustomerID));
        if (c)
          setCustomer(c);
        else
          navigate("/Consultant/Portal/Clients");
      }
    })
    setLoading(false);
  }

  const ClientsPerc = () => {
    return (100 / Clients()) * customers.length;
  }

  const Clients = () => {
    if (User.PackageID > 1)
      return 10;
    return 3;
  }

  const SaveCustomer = async () => {
    setNewCustomerSaving(true);
    if (!newCustomer.ID)
      newCustomer.ID = 0;
    await Fetch(API_ConsultantSaveCustomer, newCustomer).then((Response: Customer) => {
      let customerList: Customer[] = JSON.parse(JSON.stringify(customers));

      if (newCustomer.ID === 0) {
        customerList.push(Response);
      } else {
        customerList = customerList.map((c) => {
          if (c.ID === newCustomer.ID)
            return Response;
          return c;
        })
      }

      setCustomers(customerList);
      setNewCustomer({} as Customer);
      setAddCustomer(false);
      setCustomer(Response);
    })
    setNewCustomerSaving(false);
  }

  return (<>
    {customers.length === Clients() ? <></> : <Modal Show={addCustomer} Close={() => setAddCustomer(false)} Buttons={[{
      Text: "Cancel",
      OnClick: () => setAddCustomer(false),
      Color: "Link"
    }, {
      Text: newCustomerSaving ? <>
        <i>
          <FontAwesomeIcon icon={faSpinner} spin={true} />
        </i>
        Creating...
      </> : <>
        Create Customer
      </>,
      OnClick: () => SaveCustomer(),
      Color: "Theme",
      Disabled: newCustomerSaving
    }]} Size={'small'} Title={'New Customer'}>
      <p>
        Add the Customer Name and click <b>"Create Customer"</b> to set up the Customer data and portal.
      </p>

      <Input OnChange={(v) => setNewCustomer({ ...newCustomer, Name: v })} Placeholder={'Customer Name...'} Type={'text'} Label={'Customer Name'} Value={newCustomer.Name} />
    </Modal>}

    <div className={styles.Main}>
      {loading ? <Loading /> : <></>}

      <div className={styles.Header}>
        <div className={styles.HeaderInner}>
          {customers.length === Clients() ? <></> : <button onClick={() => setAddCustomer(true)}>
            <i>
              <FontAwesomeIcon icon={faPlus} />
            </i>
            New Customer
          </button>}
        </div>

        <div className={styles.Storage}>
          <span>{customers.length} out of {Clients()} Customers</span>
          <div style={{ width: `${ClientsPerc()}%` }} className={`${styles.StorageProgress}`}></div>
        </div>
      </div>

      <div className={styles.BodyInner}>
        <div className={styles.Side}>
          <div className={styles.SideInner}>
            {customers.length ? customers.map((c: Customer) => {
              return <Link to={`/Consultant/Portal/Clients/${EncryptValue(c.ID.toString())}`}>
                <span>{c.Name}</span>
                <i>
                  <FontAwesomeIcon icon={faChevronRight} />
                </i>
              </Link>
            }) : <div className={styles.Message}>
              <i>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </i>
              <h1>No Customers</h1>
              <p>You have not yet added any Customers, start adding them now</p>
              <button onClick={() => setAddCustomer(true)}>Add Customer</button>
            </div>}
          </div>
          <div className={`${styles.SideNav} ${Customer.ID ? styles.Show : ""}`}>
            <div className={styles.SideNavHeader}>
              {Customer.Name}
            </div>
            {Customer.ID ? <div className={styles.SideNavBody}>
              <div className={styles.SideNavBack}>
                <button onClick={() => { setCustomer({} as Customer); navigate(`/Consultant/Portal/Clients`) }}>
                  <i>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </i>
                </button>
              </div>
              <div className={styles.SideNavInner}>
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Storage`} title="Document Storage">
                  <i>
                    <FontAwesomeIcon icon={faDownload} />
                  </i>
                  <span>Document Storage</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Recruitment`} title="Recruitment Workflow">
                  <i>
                    <FontAwesomeIcon icon={faUserPlus} />
                  </i>
                  <span>Recruitment</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                <Link title="Company Calendar" to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Company Calendar`}>
                  <i>
                    <FontAwesomeIcon icon={faCalendar} />
                  </i>
                  <span>Company Calendar</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                <Link title="Employees" partiallyActive={true} to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Employees`}>
                  <i>
                    <FontAwesomeIcon icon={faUserFriends} />
                  </i>
                  <span>Employees</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Heirarchy`} title={Language.Applications.CompanyHierarchy}>
                  <i>
                    <FontAwesomeIcon icon={faSitemap} />
                  </i>
                  <span>{Language.Applications.CompanyHierarchy}</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Departments`} title={Language.Applications.Departments}>
                  <i>
                    <FontAwesomeIcon icon={faThList} />
                  </i>
                  <span>{Language.Applications.Departments}</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Teams`} title={Language.Applications.Teams}>
                  <i>

                    <FontAwesomeIcon icon={faUsers} />
                  </i>
                  <span>{Language.Applications.Teams}</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Onboarding`} title={Language.Applications.OnboardingWorkflow}>
                  <i>

                    <FontAwesomeIcon icon={faAddressCard} />
                  </i>
                  <span>{Language.Applications.OnboardingWorkflow}</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Offboarding`} title="Offboarding">
                  <i>

                    <FontAwesomeIcon icon={faUserAltSlash} />
                  </i>
                  <span>Offboarding</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Benefits`} title="Benefits">
                  <i>

                    <FontAwesomeIcon icon={faAward} />
                  </i>
                  <span>{Language.Applications.BenefitsPerks}</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Asset Management`} title={Language.Applications.AssetManagement}>
                  <i>

                    <FontAwesomeIcon icon={faLaptop} />
                  </i>
                  <span>{Language.Applications.AssetManagement}</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link >
                {User.HappinessOptionsData.length ? <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Mood Monitor`} title="Mood Monitor">
                  <i>
                    <FontAwesomeIcon icon={faFaceGrinBeam} />
                  </i>
                  <span>Mood Monitor</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link> : <></>}
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Task Manager`} title="Task Manager">
                  < i >

                    <FontAwesomeIcon icon={faTasks} />
                  </i >
                  <span>Task Manager</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link >
                <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Courses`} title="Training Resources">
                  <i>

                    <FontAwesomeIcon icon={faGraduationCap} />
                  </i>
                  <span>Training Resources</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link>
                {User.PackageID > 1 ? <Link to={`/Consultant/Portal/Clients/${EncryptValue(Customer.ID.toString())}/Policy Library`} partiallyActive={true} title={Language.Applications.PolicyLibrary}>
                  < i >
                    <FontAwesomeIcon icon={faSignature} />
                  </i >
                  <span>{Language.Applications.PolicyLibrary}</span>
                  <i>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Link > : <></>}
              </div >
            </div > : <></>}
          </div >
        </div >

        <div className={styles.Body}>
          {props.children}
        </div>
      </div >
    </div >
  </>);
};

export default ConsultantPortalCustomers;
